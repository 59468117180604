<template>

  <!-- Comment Manager -->
  <div class="comment-manager">

    <!-- Comment Form -->
    <j-card
      v-if="commentFormVisible"
      title="Add Comment"
    >
      <j-card-text>
        <CommentForm
          :comment="comment"
          class="my-4"
        />
      </j-card-text>

      <!-- Actions -->
      <template #actions>
        <!-- Save -->
        <j-button
          label="Save"
          :processing="comment.saving"
          :disabled="comment.pristine"
          @action="saveComment"
        />
      </template>

      <!-- Options -->
      <template #options>
        <!-- Cancel -->
        <j-button
          v-if="!comment.saving"
          label="Cancel"
          type="option"
          @action="HIDE_COMMENT_FORM"
        />
      </template>
    </j-card>

    <!-- Comment Prompt Text Field -->
    <v-text-field
      v-else
      placeholder="Add Comment"
      prepend-inner-icon="fa-comment"
      dense solo
      hide-details
      single-line
      class="comment-entry mb-4"
      @focus="showCommentForm"
    />

    <!-- Comment Listing -->
    <j-comment
      v-for="comment in comments.getModels()"
      :key="comment.id"
      :body="comment.body"
      :avatar="comment.author_avatar"
      :initials="comment.author_initials"
      :author="comment.author"
      :icon="visibilityIcon(comment)"
      :timestamp="comment.created_at"
    />
  </div>
</template>

<script>

// Components
import CommentForm from '@/components/panels/comments/CommentForm'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapMutations, mapGetters, mapActions } = createNamespacedHelpers('comments')

export default {
  name: 'CommentManager',
  // -------------
  // Components ==
  // -------------
  components: {
    CommentForm
  },
  // -------------
  // Properties ==
  // -------------
  props: {
    source: {
      type: Object,
      default: null
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'commentFormVisible',
      'comment',
      'comments'
    ]),
    ...mapGetters([
      'loading',
    ])
  },
  // --------
  // Watch ==
  // --------
  watch: {
    'source.tag19': {
      immediate: true,
      handler() { if (this.source.tag19) this.fetchComments(this.source) }
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    ...mapActions([
      'fetchComments',
      'showCommentForm',
      'saveComment'
    ]),
    ...mapMutations([
      'HIDE_COMMENT_FORM',
      'SET_SOURCE'
    ]),
    visibilityIcon (comment) {
      if (comment.get('visibility') == 'Editors') {
        return 'users'
      } else if (comment.get('visibility') == 'Author') {
        return 'user'
        } else {
        return 'globe'
      }
    }
  }
}
</script>
