<template>
  <j-form model="comments.comment">
    <!-- Comment Body -->
    <j-control
      name="body"
      :rows="rows"
      :autofocus="true"
    />

    <!-- Visibility -->
    <j-control
      name="visibility"
      cols="4"
    />

    <!-- Visibility Help -->
    <v-col
      cols="8"
      align-self="center"
    >
      <span class="visibility-help">{{ visibilityHelp }}</span>
    </v-col>
  </j-form>
</template>

<script>

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('comments')

export default {
  name: 'CommentForm',
  props: {
    defaultText: {
      type: String,
      default: null
    },
    rows: {
      type: String,
      default: "5"
    }
  },
  created() {
    this.comment.set('body', this.defaultText)
  },
  computed: {
    ...mapState([
      'comment'
    ]),
    visibilityHelp() {
      switch (this.comment.visibility) {
        case 'Staff':
          return 'Your comment will be visible by all Ink 19 staff'
        case 'Author':
          return 'Your comment will be visible only to you and item\'s assigned author'
        case 'Editors':
          return 'Your comment will be visible to you, the assigned author, and all editors'
        default:
          return '';
      }
    }
  }
}
</script>

<style lang="scss">

.visibility-help {
  @extend %j-label;
}

</style>