<template>

  <!-- Article Form -->
  <j-card class="article-form">
    <j-card-text>
      <j-form
        model="articles.article"
      >

        <!-- Text -->
        <template v-if="form === 'text'">

          <!-- Body -->
          <j-control
            name="body"
            rows="20"
          />

          <!-- Proofing Mark -->
          <j-control
            v-if="showProofingMark"
            name="proofed"
            :label="proofingMark"
          />

        </template>

        <!-- Metadata -->
        <template v-else-if="form === 'metadata'">

          <!-- Title -->
          <j-control name="title" />

          <!-- Byline -->
          <j-control name="byline" />

          <!-- Description -->
          <j-control
            name="description"
            rows="4"
          />

          <!-- Tags -->
          <j-control name="tags" />

          <!-- Release Date -->
          <j-control
            name="released_on"
            cols="6"
          />

          <!-- Publication Date -->
          <j-control
            name="published_at"
            label="Publication Date"
            cols="6"
          />

          <!-- Contact -->
          <j-control name="contact" cols="6" />

          <!-- Creator -->
          <j-control
            v-if="$user.isAdmin"
            name="creator_slug"
            cols="6"
          />

          <!-- Section -->
          <j-control
            v-if="$user.isAdmin"
            name="section_slug"
            cols="6"
          />

          <!-- Slug -->
          <j-control
            v-if="$user.isAdmin"
            name="slug"
            cols="6"
          />

          <!-- Opp -->
          <v-col v-if="article.opp_tag19" class="form-group pt-1 pb-0">

            <j-display
              label="Assignment"
            >
              <a :href="`/opportunities/${article.opp_tag19}`" target="_blank">
                {{ article.opp_title | truncate }}
              </a>
            </j-display>

          </v-col>

          <!-- Featured -->
          <j-control
            v-if="$user.isAdmin"
            name="featured"
            cols="6"
          />
            
        </template>
      </j-form>
    </j-card-text>
  </j-card>
</template>

<script>

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('articles')

export default {
  name: 'ArticleForm',
  props: {
    form: {
      type: String,
      default: 'body'
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'article',
    ]),
    showProofingMark () {
      return this.article.creator_slug !== this.$user.slug
    },
    sectionName () {
      if (this.article.section) {
        if (this.article.subsection) {
          return `${this.article.section.name}/${this.article.subsection}`
        } else {
          return this.article.section.name
        }
      } else {
        return ''
      }
    },
    proofingMark () {
      return `Proofed by ${this.$store.getters['profile'].get('name')}`
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    ...mapActions([
      'saveArticle',
      'cancelEditArticle'
    ]),
    handleCancel () {
      this.cancelEditArticle()
    },
    handleSave () {
      this.saveArticle()
    }
  }
}
</script>
