<template>
  <j-card>
    <v-container>

      <v-row>
        <v-col>

          <!-- Published -->
          <j-display
            v-if="article.published_at"
            label="Published"
          >
            {{ article.published_at | longDate }}
          </j-display>
          <j-display
            v-else
            label="Published"
          >
            <em>Unpublished</em>
          </j-display>

        </v-col>
        <v-col>

          <!-- Slug -->
          <j-display
            label="Slug"
          >
            {{ article.tag19 }}-{{ article.slug }}
          </j-display>

        </v-col>
      </v-row>
      <v-row>
        <v-col>

          <!-- Contact -->
          <j-display
            v-if="article.contact"
            label="Contact"
          >
            <a :href="`mailto:${article.contact}`">{{ article.contact }}</a>
          </j-display>

        </v-col>
        <v-col>

          <!-- Release Date -->
          <j-display
            v-if="article.released_on"
            label="Release / Embargo Date"
          >
            {{ article.released_on | shortDate }}
          </j-display>

        </v-col>
      </v-row>
      <v-row>
        <v-col>

          <!-- Created -->
          <j-display
            label="Created"
          >
            {{ article.created_at | shortDate }} by {{ article.creator }}
          </j-display>

        </v-col>
        <v-col>

          <!-- Updated -->
          <j-display
            label="Updated"
          >
            {{ article.updated_at | shortDate }}
          </j-display>

        </v-col>
      </v-row>
      <v-row>
        <v-col>

          <!-- Proofing -->
          <j-display
            label="Proofing"
          >
            <template v-if="article.proofing.length">
              {{ article.proofing.join(', ') }}
            </template>
            <template v-else>
              <em>Not proofed</em>
            </template>  
          </j-display>

        </v-col>
        <v-col v-if="article.opp_tag19">

          <!-- Assignment -->
          <j-display
            label="Assignment"
          >
            <router-link :to="{ name: 'opps-details', params: { tag19: article.opp_tag19 }}">
              {{ article.opp_title | truncate }}
            </router-link>
          </j-display>

        </v-col>
      </v-row>
    </v-container>
  </j-card>
</template>

<script>

export default {
  name: 'ArticleMetadata',
  // -------------
  // Properties ==
  // -------------
  props: {
    article: {
      type: Object,
      default: null
    }
  }
}
</script>
