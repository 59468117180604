<template>

  <!-- Article Record View -->
  <j-page
    :title="page.title"
    :title-prefix="page.titlePrefix"
    :subtitle="page.subtitle"
    :subtitle-color="page.subtitleColor"
    :icon="page.icon"
    :loading="loading"
    fill
    id="article-record-view"
  >

    <!-- Actions -->
    <template #actions>

      <!-- Article Tools -->
      <ArticleTools />

    </template>

    <!-- Left - Article Preview -->
    <template #left>
      
      <!-- Custody Notice -->
      <j-alert 
        v-if="show.custodyNotice"
        type="warning"
        class="mb-3"
      >
        <span v-if="custodyMessage" v-html="custodyMessage"/>
        <template v-else>
          This article is currently checked out to <strong>{{ article.custody.custodian }}</strong>.
        </template>
      </j-alert>

      <!-- Draft Notice -->
      <j-alert 
        v-if="show.draftNotice"
        class="mb-3"
      >
        This article is a <strong>Draft</strong>. When it is ready
        for editors to proofread and fact-check, use the <strong>Submit</strong>
        option in the Tools (gear) menu.
      </j-alert>

      <!-- Text Form -->
      <ArticleForm
        v-if="article.allow('editing')"
        form="text"
        class="mt-4"
      />

      <ArticlePreview
        v-else
        :article="article"
        class="article-preview mb-3"
      />
    </template>

    <!-- Right - Metadata / Text / Images / Comments -->
    <template #right>

      <!-- Aricle View -->
      <ArticleTabs />

    </template>

    <!-- Dialogs - Delete Article -->
    <template #dialogs>

      <!-- Comment modal for deleting articles -->
      <DeleteArticleDialog />

    </template>  
  </j-page>
</template>

<script>

// Components
import ArticleForm from '@/components/Articles/ArticleForm'
import ArticlePreview from '@/components/widgets/articles/ArticlePreview'
import ArticleTabs from '@/components/Articles/ArticleTabs'
import ArticleTools from '@/components/Articles/ArticleTools'
import DeleteArticleDialog from '@/components/Articles/DeleteArticleDialog'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapGetters, mapActions } = createNamespacedHelpers('articles')

// Helpers
import { capitalize } from 'lodash'

export default {
  name: 'ArticleRecordView',
  // -------------
  // Components ==
  // -------------
  components: {
    ArticleForm,
    ArticlePreview,
    ArticleTabs,
    ArticleTools,
    DeleteArticleDialog
  },
  // -------------
  // Middleware ==
  // -------------
  beforeRouteEnter(to, _from, next) {
    next(vm => {
      // Fetch article if we don't have it
      if (to.params.tag19 != vm.article.tag19) {
        vm.fetchArticle(to.params.tag19)
      }
    })
  },
  beforeRouteUpdate(to, _from, next) {
    this.fetchArticle(to.params.tag19)
    next()
  },
  beforeRouteLeave(_to, _from, next) {
    if (this.article.checkedOut) {
      this.checkInArticle()
    }
    next()
  },
  // -----------
  // Metadata ==
  // -----------
  metaInfo() {
    return {
      title: this.article.$.title || ''
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    // Vuex
    ...mapState([
      'article',
      'custodyMessage',
      'policy'
    ]),
    ...mapGetters([
      'loading'
    ]),
    // Page
    page () {
      const title = this.article.$.title
      const titlePrefix = this.article.releaseDate
      const subtitle = `${this.article.section.name} / ${capitalize(this.article.$.stage)}`
      const subtitleColor = this.article.section.highlight_color
      const icon = this.article.$.deleted_at ? 'ban' : null // Ban icon if the article is deleted
      return {
        title,
        titlePrefix,
        subtitle,
        subtitleColor,
        icon
      }
    },
    // Show
    show () {
      // Custody notice
      const custody = this.article.get('custody')
      const custodyMessage = this.custodyMessage
      const custodyNotice = !!custody.custodian || !!custodyMessage
      // Draft notice
      const draftNotice = this.article.get('stage') === 'Draft'
      return {
        custodyNotice,
        draftNotice
      }
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    // Actions
    ...mapActions([
      'checkOutArticle',
      'checkInArticle',
      'fetchArticle'
    ])
  }
}
</script>
