<template>
  <j-markdown
    class="article-body"
    :source="article.get('body')"
    :images="article.images"
    :embeds="article.embeds"
  />
</template>

<script>

export default {
  name: 'ArticleBody',
  props: {
    article: {
      type: Object,
      default: null
    }
  }
}
</script>

<style lang="scss">

.article-body {

  @extend %j-body;

  /* Info */
  .hidden-title {
    display: none;
  }
  .main-title {
    @extend %j-heading-1;
  }
  .main-subtitle {
    @extend %j-heading-3;
    font-weight: normal;
  }

  /* Music Reviews */
  .music-artist {
    @extend %j-heading-1;
  }
  .music-title {
    @extend %j-heading-2;
  }
  .music-label {
    @extend %j-heading-3;
  }

  /* Screen Reviews */
  .screen-title {
    @extend %j-heading-1;
  }
  .screen-cast {
    @extend %j-heading-2;
  }
  .screen-studio {
    @extend %j-heading-3;
  }

  /* Print Reviews */
  .print-title {
    @extend %j-heading-1;
  }
  .print-author {
    @extend %j-heading-2;
  }
  .print-publisher {
    @extend %j-heading-3;
  }

  /* Gear Reviews */
  .gear-product {
    @extend %j-heading-1;
  }
  .gear-type {
    @extend %j-heading-2;
  }
  .gear-manufacturer {
    @extend %j-heading-3;
  }

  /* Staff Picks */
  .staff-pick-artist {
    @extend %j-heading-1;
    display: none;
  }
  .staff-pick-title {
    @extend %j-heading-2;
    display: none;
  }
  .staff-pick-label {
    @extend %j-heading-3;
    display: none;
  }

  /* Body */
  // .body-first {
  //   margin: 0;
  // }
  // .body-plain {
  //   margin: 0;
  //   text-indent: 2em;
  // }
  .body-byline {
    font-weight: bold;
    margin: 0.5em 0;
  }
  .body-center {
    text-align: center;
  }
  .body-subhead {
    @extend %j-body-large;
    font-weight: bold;
  }
  .body-question {
    font-style: italic;
  }
  .body-answer {
    margin-left: 1em;
  }

  /* Reviews */
  .review-address {
    @extend %j-body-small;
    font-style: italic;
  }
  .review-byline {
    font-weight: bold;
    text-align: right;
  }

  /* Live */
  .live-headline {
    @extend %j-heading-1;
  }
  .live-supporting {
    @extend %j-heading-2;
  }
  .live-location {
    @extend %j-heading-3;
  }

  /* Media */
  // .item-image {}
  .image-graphic {
    width: 100%;
    // clip-path: url(#superellipse);
  }
  .media-fill {
    width: 100%;
  }
  .media-left {
    float: left;
    width: 50%;
  }
  .media-right {
    float: right;
    width: 50%;
  }
  .media-center {
    margin: auto;
    width: 50%;
  }
  .media-credit {
    @extend %j-label;
    text-align: right;
  }
  .media-caption {
    @extend %j-body-small;
    text-align: center;
  }

  .end-block {
    font-size: 0;
    display: inline-block;
    position: relative;
    top: 2px;
    width: 7px;
    height: 7px;
    background-color: black;
  }
}
</style>