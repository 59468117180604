<template>
  <div class="article-preview">

    <ArticleTile
      :article="article"
      :section="section"
      class="mb-4"
    />

    <j-card>

      <!-- Staff Pick Preview -->
      <StaffPickPreview
        v-if="showStaffPickPreview"
        :article="article"
      />
    
      <!-- Article Preview -->
      <j-card-text v-else>
        <ArticleBody :article="article" />
      </j-card-text>

    </j-card>
  </div>
</template>

<script>

import ArticleBody from '@/components/widgets/articles/ArticleBody'
import ArticleTile from '@/components/widgets/articles/ArticleTile'
import StaffPickPreview from '@/components/widgets/articles/StaffPickPreview'

export default {
  name: 'ArticlePreview',
  // -------------
  // Components ==
  // -------------
  components: {
    ArticleBody,
    ArticleTile,
    StaffPickPreview
  },
  // -------------
  // Properties ==
  // -------------
  props: {
    article: {
      type: Object,
      default: null
    },
    section: {
      type: Object,
      default: null
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    // Helpers
    showStaffPickPreview () {
      return this.article.section.slug == 'staff-picks'
    }
  }
}
</script>
