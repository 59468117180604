<template>
  <j-dialog
    :value="$dialog.status(dialogName)"
    :title="title"
    max-width="600px"
  >
    <!-- Comment Form -->
    <j-card-text>
      <j-alert
        v-if="message"
        :message="message"
        type="warning"
        class="mt-4"
      />

      <slot name="preview"></slot>

      <CommentForm
        :comment="comment"
        :title="title"
        :default-text="defaultText"
        rows="2"
        :save-text="saveText"
      />
    </j-card-text>

    <!-- Actions -->
    <template #actions>
      <!-- Save -->
      <j-button
        :label="saveText"
        :processing="comment.saving"
        :disabled="comment.pristine"
        @action="handleSave"
      />
    </template>

    <!-- Options -->
    <template #options>
      <!-- Cancel -->
      <j-button
        v-if="!comment.saving"
        label="Close"
        type="option"
        @action="handleCancel"
      />
    </template>
  </j-dialog>
</template>

<script>

// Components
import CommentForm from '@/components/panels/comments/CommentForm'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapMutations, mapActions } = createNamespacedHelpers('comments')

export default {
  name: 'CommentDialog',
  // -------------
  // Components ==
  // -------------
  components: {
    CommentForm
  },
  // -------------
  // Properties ==
  // -------------
  props: {
    dialogName: {
      type: String,
      required: true
    },
    show: {
      type: Boolean,
      default: false
    },
    source: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    defaultText: {
      type: String,
      default: null
    },
    error: {
      type: String,
      default: ''
    },
    saveText: {
      type: String,
      default: 'Save'
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'comment'
    ])
  },
  // --------
  // Watch ==
  // --------
  watch: {
    source: {
      immediate: true,
      handler () {
        this.reset()
      }
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    ...mapActions([
      'saveComment'
    ]),
    ...mapMutations([
      'NEW_COMMENT',
      'SET_SOURCE'
    ]),
    handleCancel () {
      this.$emit('cancel')
    },
    handleSave () {
      this.saveComment()
      this.$emit('save')
    },
    reset () {
      this.SET_SOURCE(this.source)
      this.NEW_COMMENT()
    }
  }
}
</script>
