<template>

  <!-- Article Tools -->
  <div class="article-tools">
    <j-menu
      v-if="article.allow('showTools')"
      icon="cog"
      button-type="icon"
    >

      <!-- Edit -->
      <j-menu-item
        icon="edit"
        label="Edit"
        @click="handleCheckout"
      />

      <!-- Send to Drafts -->
      <j-menu-item
        v-if="article.allow('sendToDraft')"
        icon="file"
        label="Send to Drafts"
        @click="handleSend('draft')"
      />

      <!-- Send to Copy -->
      <j-menu-item
        v-if="article.allow('sendToCopy')"
        icon="inbox"
        :label="labels.sendToCopy"
        @click="handleSend('copy')"
      />

      <!-- Release -->
      <j-menu-item
        v-if="article.allow('sendToReleased')"
        icon="calendar"
        label="Release"
        @click="handleSend('released')"
      />

      <!-- Republish -->
      <j-menu-item
        v-if="article.allow('republish')"
        icon="calendar"
        label="Republish"
        @click="handleRepublish"
      />

      <!-- Delete -->
      <j-menu-item
        v-if="article.allow('sendToDeleted')"
        icon="times"
        label="Delete"
        @click="handleSend('deleted')"
      />

      <!-- Create Another -->
      <j-menu-item
        icon="copy"
        :label="labels.createAnother"
        @click="handleCreateAnother()"
      />
    </j-menu>

    <!-- Article Index Button -->
    <j-button
      v-if="article.allow('showIndex')"
      type="icon"
      help="Article Index"
      icon="list"
      @action="handleIndex"
    />

    <!-- Article Form Actions -->
    <j-actions
      v-if="article.allow('showActions')"
      :record="article"
      @cancel="handleCancelEdit"
      @save="handleSave"
    />

  </div>
</template>

<script>

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('articles')

export default {
  name: 'ArticleTools',
  // -----------
  // Computed ==
  // -----------
  computed: {
    // State
    ...mapState([
      'article'
    ]),
    // Labels
    labels () {
      const sendToCopy = this.article.stage === 'Draft' ? 'Submit' : 'Copy Desk'
      const createAnother = `New Draft in ${this.article.section.name}`
      return {
        sendToCopy,
        createAnother
      }
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    // Actions
    ...mapActions([
      'cancelEditArticle',
      'checkOutArticle',
      'saveArticle',
      'sendArticle',
      'republishArticle'
    ]),
    // -----------
    // Handlers ==
    // -----------

    // Index
    handleIndex () {
      this.$router.push({ name: 'articles-index' })
    },

    // Checkout
    handleCheckout () {
      this.checkOutArticle()
    },

    // Send
    handleSend(stage) {
      this.sendArticle(stage).then(() => {
        if (stage != 'deleted') {
          const stageNames = {
            draft: 'Drafts',
            copy: 'Copy Desk',
            released: 'Scheduling'
          }
          this.$store.commit('addNotice', { message: `The article was sent to <strong>${stageNames[stage]}</strong>` })
        }
      })
    },

    // Republish
    handleRepublish () {
      this.republishArticle().then(() =>
        this.$store.commit('addNotice', { message: `The article was republished` })
      ).catch(() => 
        this.$store.commit('addError', { message: `Something happened and the article was not republished` })
      )
    },

    // Cancel Edit
    handleCancelEdit () {
      this.cancelEditArticle()
    },

    // Create Another
    handleCreateAnother () {
      this.$router.push({ name: 'draft-form', params: { type: 'section', id: this.article.section.slug }})
    },

    // Save
    handleSave () {
      this.saveArticle()
    }
  }
}
</script>
