<template>
  <j-card-text class="pt-4">
    <j-graphic
      :src="article.badge_src"
      :alt="article.title"
      aspect-ratio="1"
      width="100%"
    />
    <ArticleBody
      :article="article"
      class="staff-pick-review pa-2"
    />
  </j-card-text>
</template>

<script>

import ArticleBody from '@/components/widgets/articles/ArticleBody'

export default {
  name: 'StaffPickPreview',
  // -------------
  // Components ==
  // -------------
  components: {
    ArticleBody
  },
  // -------------
  // Properties ==
  // -------------
  props: {
    article: {
      type: Object,
      default: null
    },
    section: {
      type: Object,
      default: null
    }
  }
}
</script>

<style lang="scss">

  .staff-pick-review {
    font-size: 90%;
    line-height: 1.5;
    .body-first { margin-bottom: 0.5em; }
    .review-byline { margin-bottom: 1.5em; }
  }

</style>