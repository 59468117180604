<template>

  <!-- Delete Article Dialog -->
  <CommentDialog
    dialog-name="delete-article"
    :title="`Delete &quot;${article.title}&quot;`"
    message="Please provide some information on this action..."
    :source="article"
    save-text="Delete"
    class="delete-article-dialog"
    @save="handleConfirmDeletion"
    @cancel="handleCancelDeletion"
  />
</template>

<script>

// Components
import CommentDialog from '@/components/panels/comments/CommentDialog'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('articles')

export default {
  name: 'DeleteArticleDialog',
  // -------------
  // Components ==
  // -------------
  components: {
    CommentDialog
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'article'
    ])
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    ...mapActions([
      'confirmDeletion',
      'cancelDeletion'
    ]),
    handleConfirmDeletion () {
      this.confirmDeletion()
    },
    handleCancelDeletion () {
      this.cancelDeletion()
    }
  }
}
</script>
