<template>

  <!-- Article Tabs -->
  <v-tabs
    v-model="tabs"
    fixed-tabs
    class="article-tabs"
  >

    <!-- Details Tab -->
    <j-tab
      href="#details"
      icon="list"
      :count="commentCount"
    >
      Details
    </j-tab>

    <!-- Media Tab -->
    <j-tab
      href="#media"
      icon="images"
      :count="imageCount + embedCount"
    >
      Media
    </j-tab>

    <!-- Preview Tab -->
    <j-tab
      v-if="article.allow('editing')"
      href="#preview"
      icon="file"
    >
      Preview
    </j-tab>

    <!-- Metadata Panel -->
    <v-tab-item value="details">

      <!-- Metadata Form -->
      <ArticleForm
        v-if="article.allow('editing')"
        form="metadata"
        class="mt-3"
      />

      <!-- Metadata Widget -->
      <ArticleMetadata
        v-else
        :article="article"
        class="mt-3"
      />

      <!-- Comment Manager -->
      <CommentManager
        :source="article"
        class="mt-4"
      />

    </v-tab-item>

    <!-- Media Panel -->
    <v-tab-item value="media">
      <MediaManager
        :source="article"
        :editable="article.allow('editable') && !article.allow('editing')"
        class="mt-3"
      />
    </v-tab-item>

    <!-- Preview Panel -->
    <v-tab-item
      v-if="article.allow('editing')"
      value="preview"
    >
      <ArticlePreview
        :article="article"
        class="mt-3"
      />
    </v-tab-item>

  </v-tabs>
</template>

<script>

// Components
import ArticleForm from '@/components/Articles/ArticleForm'
import ArticleMetadata from '@/components/widgets/articles/ArticleMetadata'
import ArticlePreview from '@/components/widgets/articles/ArticlePreview'
import CommentManager from '@/components/panels/comments/CommentManager'
import MediaManager from '@/components/Media/MediaManager'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapMutations } = createNamespacedHelpers('articles')

export default {
  name: 'ArticleTabs',
  // -------------
  // Components ==
  // -------------
  components: {
    ArticleForm,
    ArticleMetadata,
    ArticlePreview,
    CommentManager,
    MediaManager
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    // State
    ...mapState([
      'articleTab',
      'article',
    ]),
    // Tabs
    tabs: {
      get() { return this.articleTab },
      set(tab) { this.SET_TAB(tab) }
    },
    // Embed count
    embedCount () {
      return this.article.embeds.length
    },
    // Image count
    imageCount () {
      return this.article.images.length
    },
    // Comment count
    commentCount () {
      return this.article.comment_count
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    // Mutations
    ...mapMutations([
      'SET_TAB'
    ])
  }
}
</script>
